import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; //withStyles
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import from_rna from './svg/from_rna.svg'
// import from_rna_2 from './svg/from_rna_2.svg'
import workflow from './svg/workflow.svg'
// import Decorator from '../components/HtmlTooltip';
// import Divider from '@material-ui/core/Divider';
// import InputsTwo from '../components/TextAdd_two';
// import InputThree from '../components/InputThree';
// import classNames from 'classnames';
// import { connect } from 'react-redux';
// import TableNewSuper from '../components/TableNewSuper';
import WarningMessage from '../components/MessageSnackbar';
// import TableMetadata from '../components/TableMetadata';
// import BasicTableRNA from '../components/TABLEpartBasic';
// import TrackListCheckboxes from '../components/TrackListCheckboxes';

import { fetchGenes, handleGeneSelect, changeCurrentGeneInput } from '../actions/genesActions';
import { fetchGeneTypes, handleGeneTypesSelect, changeCurrentGeneTypesInput } from '../actions/genesActions';
import { fetchAnnotation, handleAnnotationSelect, changeCurrentAnnotationInput } from '../actions/genesActions';
import {textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange} from '../actions/generalActions';
import { textChangeSearchGeneName } from '../actions/generalActions';
import { handleHeader } from '../actions/generalActions';

import OrganismSelectorSimple from '../components/AutoSuggestOrganismSimple';
import GeneSelector from '../components/AutoSuggest';
// import GeneTypesSelector from '../components/AutoSuggestTypeGenes';
// import AnnotationSelector from '../components/AutoSuggestAnnotation';


const module = "genes";
const moduleTable = "table";
const moduleBasicAnalysis = "basicAnalysis";
const moduleFilter = "filter";
const moduleText = "text";

// const rows_fromRNA = [
//   // {id: 'id', numeric: false, disablePadding: false, label: 'ID'},
//   {id:'gene_name', numeric: false, disablePadding: false, label: 'Annotated name' },
//   {id:'gene_type', numeric: false, disablePadding: true, label: 'Gene type'},
//   {id:'locus', numeric: false, disablePadding: true, label: 'Gene locus'},
//   {id:'len', numeric: false, disablePadding: true, label: 'Length'}, 
//   {id:'strand', numeric: false, disablePadding: true, label: 'Str.' },
//   {id:'all_vs_all', numeric: false, disablePadding: true, label: 'All-vs-all'}, // (max CPKM)
//   {id:'one_vs_all', numeric: false, disablePadding: true, label: 'One-vs-all'}, //(max CPKM)
//   {id:'from', numeric: false, disablePadding: true, label: 'Ann.source'},
// ];
const organismName = (name) => {
  let organism_name = ""
  if (name === "Homo sapiens") {
    organism_name = "H. sap."
  } else if (name === "Mus musculus") {
    organism_name = "M. mus."
  } else if (name === "Sus scrofa") {
    organism_name = "S. scr."
  } else if (name === "Anolis carolinensis") {
    organism_name = "A. car."
  } else {
    organism_name = "ERROR"
  }
  return organism_name
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 40,
    marginTop: theme.spacing(3),
  },
  link: {
    color: 'rgb(0, 150, 136)',
    '&:hover': {
      color: 'grey',
    }
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
  tabs: {
    color: 'black',
  },
  loading: {
    marginTop: 150,
    textAlign: 'center',
  },
  button: {
    // padding: theme.spacing(),
    width: '90%',
  },
  wideButton: {
    padding: theme.spacing(),
    width: '95%',
    // marginTop: theme.spacing(),
  },
  autocomplete: {
    flexGrow: 1,
    paddingTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
}));

const BasicFromRna = (props) => {

  const classes = useStyles();
  document.title = "RNA-Chrom: from RNA";
  
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  // const [extraParams, setExtraParams] = useState({'from': [], 'gene_type': [], 'gene_name': [], 'SearchGeneName': '', 'LocusCoordinatesForRNAs': ''})
  // const [currentOrganism, setCurrentOrganism] = useState('Homo sapiens')

  useEffect(() => {
    // props.handleHeader(': start analysis by selecting RNA')
    props.handleHeader(": where does the selected RNA contact with chromatin?")
    // setCurrentOrganism(props.selectedItemOrganism[0]['value'])
  }, [])

  // const handleClickApply = (e,id) => {   
  //   console.log('handleClickApply')
  //   if (props.locusWarning === false) { 
  //     setExtraParams({'from': props.selectedItemAnnotation, 
  //                     'gene_type': props.selectedItemGeneTypes, 
  //                     'gene_name': props.selectedItem, 
  //                     'SearchGeneName': props.valueSearchGeneName, 
  //                     'LocusCoordinatesForRNAs': props.currentLocus
  //                   })
  //     setCurrentOrganism(props.selectedItemOrganism[0]['value'])
  //   } else {
  //     setMessage("Locus coordinates were entered in the wrong format");
  //     setOpen(true)
  //   }
  // };

  const handleViewGenesClick = (event) => {
    // debugger
    const data = {
      'organism': props.selectedItemOrganism[0]['value'], 
    } 
    localStorage.setItem("browse_rnas",  JSON.stringify(data));
    window.open("/from_rna/browse_rnas", "_blank");
  }

  const handleNewWindowClick = (event, label) => {
    if (props.selectedItem.length !== 0) {
        const data_2 = {
          selectedTracks: -666,
          typeOfContacts: props.typeOfContacts + '___' + props.selectedItem.map(n => n['value']),
          selectedTrackNames: props.selectedItemOrganism[0]['value'], //currentOrganism, 
          selectedNormalization: props.trackListCheckbox,
          gene_name_target: "",
        }
        localStorage.setItem("data_rna_list",  JSON.stringify(data_2));    
        
        const data = {
              'RNAname': props.selectedItem.map(n => n['value']),
              'selectedId': -666,
              typeOfContacts: props.typeOfContacts + '___' + props.selectedItem.map(n => n['value']),
              'organism': props.selectedItemOrganism[0]['value'],//currentOrganism, 
              'selectedNormalization': props.trackListCheckbox,
            } 
        // console.log('QWJKJKOFKJ!!!!!!!!!!! DATA', data)
        localStorage.setItem("data_basic_graphical_summary",  JSON.stringify(data));
        window.open("/basic_graphical_summary", "_blank");
    } else {
      setMessage('Choose some RNA')
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setMessage('')
  }
  
  return (
    <div className={classes.root}>
              <div>
              <Grid container spacing={0}>
                <Grid item xs={9}>
                    <Paper elevation={0} square> 
                    {/* style={{border: '2px solid #d3d4d5'}} */}
                        <a id="from_rna" >
                            <img src={workflow} alt="workflow" width='100%'/>
                        </a>
                    </Paper>
                </Grid>
                <Grid item xs={3} style={{borderLeft: '2px solid #000000'}}> 
                            {/* <Typography variant="h5" style={{ textAlign: 'center'}}>
                              Where is the selected RNA contacted with chromatin?
                            </Typography> */}
                            {/* <Typography variant={"subtitle1"} style={{ marginBottom: 0,}}>
                              <ul>
                                <li>select one RNA:</li>
                              </ul>
                            </Typography> */}
                            {/* <Grid container spacing={0}>
                              <Grid item xs={8} style={{marginTop: 9}}> */}
                                <div style={{ margin: '8px'}}>
                                  <OrganismSelectorSimple first_working_page={false}/>
                                </div>
                                <GeneSelector 
                                  disabled={props.loadingGenes}
                                  fetchGenes={props.fetchGenes}
                                  handleGeneSelect={props.handleGeneSelect}
                                  changeCurrentGeneInput={props.changeCurrentGeneInput}
                                  selectedItem={props.selectedItem}
                                  placeholderText={'Select RNA'}
                                  currentInput={props.currentInput}
                                  parent={'BasicFromRna'}
                                />
                                {/* </Grid>
                              <Grid item xs={4}> */}
                              <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                                <Button
                                    className={classes.wideButton}
                                    variant="contained"
                                    size="small"
                                    // style={{backgroundColor: '#96000E', color: 'white'}}
                                    color={"secondary"}
                                    // disabled={props.selectedItem.length === 0}
                                    onClick={e => handleNewWindowClick(e, "basic charts")}
                                >
                                    Graphical summary
                                </Button>
                              </div>
                              <Typography variant={"subtitle1"} style={{ marginLeft: 10, marginBottom: 0,}}>
                                  Or select RNA from all available (<i>{organismName(props.selectedItemOrganism[0]['value'])}</i>):
                              </Typography>
                              <div style={{textAlign: 'center', marginBottom: 10, marginTop: 10}}>
                                <Button
                                      className={classes.wideButton} //.button
                                      variant="contained"
                                      size="small"
                                      // style={{backgroundColor: '#96000E', color: 'white'}}
                                      color={"primary"}
                                      // disabled={props.loadingBasicGeneTable}
                                      onClick={e => handleViewGenesClick(e)}
                                  >
                                      Browse all RNAs
                                  </Button>
                              </div>
                              {/* </Grid>
                            </Grid> */}     
                </Grid>
            </Grid>
            </div>
        <Typography variant={"subtitle1"} style={{ padding: 5}}>
            If you want to report a bug or ask any questions, please contact us: <b>ryabykhgrigory@bioinf.fbb.msu.ru</b><br/>
        </Typography>
        <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={message} />
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
      AllExps: state[moduleTable].AllExps,

      loadingGenes: state[module].loadingGenes,
      selectedItem: state[module].selectedItem,
      currentInput: state[module].currentGeneInput,

      loadingGeneTypes: state[module].loadingGeneTypes,
      selectedItemGeneTypes: state[module].selectedItemGeneTypes,
      currentGeneTypesInput: state[module].currentGeneTypesInput,

      loadingAnnotation: state[module].loadingAnnotation,
      selectedItemAnnotation: state[module].selectedItemAnnotation,
      currentAnnotationInput: state[module].currentAnnotationInput,

      
      
      selectedItemOrganism: state[moduleTable].selectedItemOrganism,
      selectedId: state[moduleBasicAnalysis].selectedId,
      typeOfContacts: state[module].typeOfContacts,
      trackListCheckbox: state[moduleFilter].trackListCheckbox,
      
      valueLocus: state[moduleText].value,
      valueSearchGeneName: state[moduleText].valueSearchGeneName,

      locusWarning: state[moduleText].locusWarning,
      currentLocus: state[moduleText].currentLocus,

      basicGeneTableData: state[moduleBasicAnalysis].basicGeneTableData,
      loadingBasicGeneTable: state[moduleBasicAnalysis].loadingBasicGeneTable,
      snackOpenBasicGeneTable: state[moduleBasicAnalysis].snackOpenBasicGeneTable,
      snackTextBasicGeneTable: state[moduleBasicAnalysis].snackTextBasicGeneTable,
  }
}

export default connect(mapStateToProps, 
  { handleHeader,
    fetchGenes, handleGeneSelect, changeCurrentGeneInput,
    fetchGeneTypes, handleGeneTypesSelect, changeCurrentGeneTypesInput,
    fetchAnnotation, handleAnnotationSelect, changeCurrentAnnotationInput,    
    textChange, locusWarningChangeFalse,locusWarningChangeTrue,currentLocusChange,
    textChangeSearchGeneName,
  })(BasicFromRna);