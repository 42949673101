import React, { useEffect }  from 'react';
// import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import classNames from 'classnames';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import { clearRNAText } from '../actions/generalActions';

// const moduleText = "text";
const moduleTable = "table";
const moduleFilter = "filter";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      paddingTop: theme.spacing(),
      // paddingBottom: theme.spacing(),
      marginRight: theme.spacing(2),
      width: '100%',
    },
    container: {
        // display: 'flex',
        // flexGrow: 1,
        flexWrap: 'wrap',
        // width: '200%',
    },
    input: {
        // margin: theme.spacing(),
        // display: 'flex',
        // padding: 0,
        width: '100%',
    },
    // location: {
    //     paddingTop: theme.spacing(2),
    //     paddingBottom: theme.spacing(2),
    //     // width: '100%',
    //     // marginLeft: theme.spacing(3),
    //     // marginBottom: 10,
    //     // marginTop: 10,
    //     textAlign: 'center',
    //   },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%',
        // padding: 0,
        // width: '100%',
      },
    width: {
        width: '94%',
    },
}));

const ValidationTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#009688',
          },
        '& input': {
            // padding:"10px 16px",
            width: '100%',
        },
        '& input:valid + fieldset': {
            borderColor: '#009688',
            borderWidth: 2,
            // padding: 0,
        },
        '& input:invalid + fieldset': {
            borderColor: '#009688',
            borderWidth: 2,
            // padding: 0,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            borderColor: '#009688',
            // padding: '4px !important', // override inline-style
            // padding: 0,
        },
    },
  })(TextField);


const InputsTwo = (props) => {
    
    const { nameSelect, valueLocus, selectedItemOrganism, organism_chrs } = props; //placeholder
    const classes = useStyles();

    useEffect(() => {
      // console.log('www2 valueLocus first',valueLocus)  
      props.clearRNAText();
    }, [])
    // console.log('www2 valueLocus',valueLocus)
    
    useEffect(() => {
      // debugger
        for (const one of valueLocus.replace(/;$/g, '').split(';').map(function(item) {return item.trim();})) {
          var locus_array = one.replace(/,+|\s+/g, '').split(/r|:|-/i).map(function(item) {return item.trim();})
          // console.log('locus_array',locus_array)
          if (selectedItemOrganism.length !== 0) {
            // console.log('valueLocus',valueLocus)
            // console.log('selectedItemOrganism',selectedItemOrganism,organism_chrs)
            if (locus_array.length === 2 && locus_array[0].toLowerCase() === 'ch' && organism_chrs[selectedItemOrganism[0]['value']].indexOf(locus_array[1].replace(/^0*/,'').toLowerCase()) !== -1) {
              props.currentLocusChange(valueLocus.replace(/;$/g, '').replace(/,+|\s+/g, '').toLowerCase())
              props.locusWarningChangeFalse()
            } else if (locus_array.length === 4 && locus_array[3] !== '') {
              if (locus_array[0].toLowerCase() === 'ch' && Number(locus_array[2].replace(/^0*/,'')) < Number(locus_array[3].replace(/^0*/,'')) && organism_chrs[selectedItemOrganism[0]['label']].indexOf(locus_array[1].replace(/^0*/,'').toLowerCase()) !== -1) {
                props.currentLocusChange(valueLocus.replace(/;$/g, '').replace(/,+|\s+/g, '').toLowerCase())
                props.locusWarningChangeFalse()
              } else {
                // console.log('www2 sc')
                props.currentLocusChange('')
                props.locusWarningChangeTrue()
                break;
              }
            } else {
              if (valueLocus.length === 0) {  
                props.locusWarningChangeFalse()
              } else {
                props.locusWarningChangeTrue()
                break;
              }
              props.currentLocusChange('')
            }
          }
        }
      }, [valueLocus,selectedItemOrganism])

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.width}>
                {/* <div>
                    <FormLabel component="legend" style={{transform: "translate(14px, 10px) scale(1)",}}>{nameLabel}</FormLabel>
                </div> */}
                    <form className={classes.container} >
                        <ValidationTextField
                            label={nameSelect}
                            value={valueLocus}
                            multiline
                            size="small"
                            onKeyPress={(ev) => {
                              // console.log('ev.key',ev.key)
                              if (ev.key === 'Enter') {
                                // console.log(`цштаицаумто!!!!!!!!!!1`);
                                ev.preventDefault();
                              }
                            }}
                            // className={classes.input}
                            className={classNames(classes.input, classes.textField)}
                            onChange={props.textChange}
                            // margin="normal"
                            variant="outlined"
                            // defaultValue="Success"
                            id="validation-outlined-input"
                            // style={{borderColor: '#009688'}}
                            // className={classes.root}
                            // color='green'
                            // placeholder={placeholder}
                        />
                        {/* <CssTextField
                            label={nameSelect}
                            value={props.valueLocus}
                            className={classes.input}
                            error={true}
                            variant="outlined"
                            id="custom-css-outlined-input"
                            onChange={props.textChange}
                        /> */}
                    </form>
            </FormControl>
            {/* <WarningMessage open={open} severity={"warning"} handleClose={handleClose} message={"No valid mask!"} /> */}
        </div>
    );
}
        

const mapStateToProps = (state) => {
    return {
        organism_chrs: state[moduleFilter].organism_chrs,
        // valueLocus: state[moduleText].value,
        selectedItemOrganism: state[moduleTable].selectedItemOrganism,
    }
  }
export default connect(mapStateToProps, {clearRNAText})(InputsTwo);